<template>
  <div class="register">
    <!--注册-->
    <div class="form">
      <div class="item">
        <input
          type="text"
          name="name"
          placeholder="请输入名称"
          autocomplete="off"
          class="address"
          v-model="name"
        />
      </div>
      <div class="item" @click="showArea = true">
        <input
          type="text"
          name="name"
          disabled
          placeholder="点击选择省市县"
          autocomplete="off"
          class="address"
          v-model="ssx"
        />
      </div>
      <div class="item">
        <input
          type="text"
          name="name"
          placeholder="地址"
          autocomplete="off"
          class="address"
          v-model="address"
        />
      </div>

      <div
        class="user-container van-hairline--surround"
        style="padding: 0.2rem; margin-bottom: 0.3rem"
        v-for="(item, index) in users"
        :key="index"
      >
        <div class="item">
          <input
            type="text"
            name="username"
            placeholder="请输入姓名"
            autocomplete="off"
            class="user"
            v-model="item.username"
          />
        </div>
        <div class="item">
          <input
            type="text"
            name="mobile"
            maxlength="11"
            placeholder="请输入手机号"
            autocomplete="off"
            class="name"
            v-model="item.mobile"
          />
        </div>
        <div class="item">
          <input
            type="password"
            name="password"
            placeholder="请输入密码"
            autocomplete="off"
            class="password"
            v-model="item.password"
          />
        </div>
      </div>
      <div style="text-align: right" @click="add_user">
        <van-icon color="#1f6ecf" name="add" />
      </div>

      <!-- <p>忘记密码</p> -->
      <div class="submit"><button @click="do_register">申请开通</button></div>
    </div>
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
    <div class="footer">
      <!-- <img src="../../assets/picture/logo.png" /> -->
      <p>
        注册使用就表示同意用户协议<a
          href="javascript:;"
          @click="onAgreementClick"
          >条款和隐私</a>政策
      </p>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
// import cookies from "../../utils/cookies";
import { areaList } from "@vant/area-data";
export default {
  name: "register",
  components: {},
  data() {
    return {
      showArea: false,
      name: "",
      address: "",
      province_code: "",
      city_code: "",
      district_code: "",
      ssx: "",
      users: [
        {
          username: "",
          mobile: "",
          password: "",
        },
      ],
      areaList,
    };
  },
  methods: {
    onAgreementClick() {
      this.$router.push("/agreement");
    },
    onPrivacyClick() {
      this.$router.push("/privacy");
    },
    onConfirm(values) {
      this.province_code = values[0].code;
      this.city_code = values[1].code;
      this.district_code = values[2].code;
      this.ssx = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;

    },
    add_user() {
      this.users.push({
        username: "",
        mobile: "",
        password: "",
      });
    },
    async do_register() {
      const _self = this;
      const boss = {
        province_code: _self.province_code,
        city_code: _self.city_code,
        district_code: _self.district_code,
        users: _self.users,
        address: _self.address,
        name: _self.name,
      };
      const res = await this.$apis.create_boss(boss);
      if (res.status == 200) {
        Toast("开通成功！请登录！");
        this.$router.push("/login");
      }
    },
    validateBtn() {
      //倒计时
      this.show = false;
      let time = 10;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    validatemobile() {
      return true;
      //判断输入的手机号是否合法
      if (!this.mobile) {
        Toast("请输入手机号");
        return false;
      } else if (!/^1[345678]\d{9}$/.test(this.mobile)) {
        Toast("请输入正确的手机号!");
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
  },
};
</script>
<style scoped>
@import "./style.css";
</style>